import React, { useState, useEffect, Fragment } from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  Tab,
  Modal,
  Box,
  TextField,
  List,
  ListItem,
  Tabs,
  Tooltip,
  Typography,
  InputAdornment,
  CircularProgress
} from '@material-ui/core/'
import {timeDifference} from 'helpers.js'
import { API } from 'aws-amplify';
import { billing_plans } from 'configuration/specs.js'



const ENSO_SUBSCRIPTION_ID = 'enso'


const STAGES = ['master', 'qa', 'prod']
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export default function DiscountEditor({currency, savePlan, newPlan}){
    const [selectedInterval, setSelectedInterval] = useState(0)
    const [plan, setPlan] = useState({
        interval: 'month', 
        value: 10,
        tiers: [],
        discount: {
            discount: 10
        }
    })
    let intervals = ['month', 'quarter', 'year']


    useEffect(() => {
        if (!newPlan){
            return null
        }
        setPlan(newPlan)
    }, [newPlan]);


    function editPlan(field, val){
        let upd = {[field]: val}
        if (field === 'monthly_discount'){
            val.applied_on = Date.now()
            upd = {monthly_discount: val}
        }
        setPlan({
            ...plan,
            ...upd
        })
    }

    function setTiers(tiers){
        setPlan({
            ...plan,
            tiers: tiers
        })
    }


    function calcDiscount(tier){
        let val = tier.value
        if (selectedInterval === 1){
            return val * (100 - (plan.quarterly_discount?.value || 0))/100
        } else if (selectedInterval === 2){
            return val * (100 - (plan.yearly_discount?.value || 0))/100
        } else {
            return val
        }
    }


    function addTier(){
        setTiers([...plan.tiers, {min_quantity: null, max_quantity: null, value: null}])
    }


    function editTier(i, new_tier){
        let newtiers = Object.assign([], plan.tiers)
        newtiers[i] = new_tier
        setTiers(newtiers)
    }


    function removeTier(i){
        let newtiers = Object.assign([], plan.tiers)
        newtiers.splice(i, 1)
        newtiers[newtiers.length - 1].max_quantity = undefined
        setTiers(newtiers)
    }

    function BillingTier(t, i){
        let previous_max = (plan.tiers?.[i - 1]?.max_quantity + 1) || 0
        return <div style={{border: '1px solid grey', borderRadius: '10px', padding: 10, marginBottom: 10}}>
            <Typography variant='h6'>{previous_max} - {t.max_quantity} {plan.unit}s - {calcDiscount(t)} {currency} / {plan.unit}</Typography>
            {(i !== (plan.tiers.length -1)) && <div>
                <TextField 
                    label='Max #' type='number' value={t.max_quantity} 
                    onChange={e => editTier(i, {...t, max_quantity: parseInt(e.target.value)})}
                />
            </div>}
            <div>
                <TextField 
                    InputProps={{endAdornment: <InputAdornment position="start">{currency}</InputAdornment>}}
                    label={`base price / ${plan.unit}`} type='number' value={t.value} 
                    onChange={e => editTier(i, {...t, value: e.target.value})}
                />
            </div>
            <Button onClick={() => removeTier(i)}>DELETE TIER</Button>
        </div>
    }

    function discount_editor(discount){
        if (!!discount){
            // print discount and show delete button
        } else {

        }
    }


    let discount_key = 'monthly_discount'
    let discount_exists = false

    return <div>
        {/*<Typography variant='h5'>{plan.name}</Typography>*/}
        <div style={{display: 'flex', alignItems: 'center'}}>
            <div style={{flex: 1}}>
                <Typography variant='h5' style={{marginTop: 20}}>Discounts for {plan.name} </Typography>
            </div>
        </div>
        <Card style={{maxWidth: 600, marginTop: 10}}>
        <CardContent>
            <Typography style={{marginBottom: 10}} variant='h5'>Edit Discount</Typography>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField 
                        label={`Discount amount in ${currency}`} type='number' 
                        value={plan[discount_key]?.value || 0} fullWidth
                        onChange={e => editPlan(discount_key, {...plan[discount_key], value: parseInt(e.target.value), name: 'Prepayment discount'})}
                    />
                    <TextField 
                        label='Duration in months' type='number' 
                        value={plan[discount_key]?.duration_in_months || 0} 
                        onChange={e => editPlan(discount_key, {...plan[discount_key], duration_in_months: parseInt(e.target.value), name: 'Prepayment discount'})}
                    />
                </Grid>
            </Grid>
            <div style={{display: 'flex'}}>
              <div style={{flex: 1}}/>
                <div>
                    <Button color='primary' variant='contained' onClick={() => savePlan(plan)}>Create Discount</Button>

                </div>
            </div>
            <div style={{display: 'flex'}}>
              <div style={{flex: 1}}/>
                <div>
                  
                    <Typography>Clicking save will reset the duration of the existing discount</Typography>
                </div>
            </div>
        </CardContent>
        </Card>
    </div>

}