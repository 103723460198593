import React, {useEffect} from "react";
import { API, Auth, Hub } from "aws-amplify";
import { Redirect } from "react-router-dom";
import LoginForm from "components/LoginForm.jsx";
import GoogleIcon from '@mui/icons-material/Google';
import { Button, Grid, Typography } from '@mui/material';

const queryParams = new URLSearchParams(window.location.search);


export default function Login(){
  const [state, setState] = React.useState({
    password: queryParams.get('password', ''),
    email: queryParams.get('email', ''),
    username: "",
    wrong_pass: false,
    wrong_user: false,
    logged_in: false,
    redirect: "",
    loading: false,
    error: false,
    network: false
  })

  /*handleResponse = e => {
    // Wrong pass
    if (e.code === "NotAuthorizedException") {
      this.setState({
        wrong_pass: true,
        wrong_user: false,
      })
    }
    // No mail
    else if (e.code === "UserNotFoundException") {
      this.setState({
        wrong_user: true,
      })
    }
    else if (e.code === "NetworkError") {
      this.setState({
        network: true,
      })
    }
    else if ("username" in e) {
      Auth.currentAuthenticatedUser().then(user => {
          this.props.setCurrentUser(user)
          this.setState({logged_in: true})
      })
    }
  }


  handleChange = e => {
    this.setState({ [e.target.id]: e.target.value, loading: false, wrong_user: false, wrong_pass: false });
  }*/


  useEffect(() => {
    console.log("CHECKIN AUTH")
    Hub.listen('auth', (data) => {
      const { payload } = data;
      console.log("GOT PAYLOAD", payload)
      if (payload.event === 'signIn') {
        console.log('User signed in');
        setState({logged_in: true})
        // Handle user signed in
      }
      if (payload.event === 'signOut') {
        console.log('User signed out');
        // Handle user signed out
      }
    });

    // Check user session on load
    Auth.currentAuthenticatedUser()
      .then(user => {
        setState({logged_in: true})
        console.log("GOT USER", user)
      })
      .catch(err => console.log(err));

  }, [])



  if (state.logged_in) {
    return <Redirect push to="/admin/accounts" />;
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
     <Grid
        container style={{ margin: '1rem 0' }}
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Grid style={{marginBottom: 20}} item>
          <img
            alt="Enso Connect logo" style={{width: 200}}
            src="https://enso-image-assets.s3.amazonaws.com/enso_blue_logo.svg"
          />            
        </Grid>
        <Grid style={{marginBottom: 20}} item>
          <Typography variant='h4' color='primary'>Internal Tools</Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            startIcon={<GoogleIcon />}
            onClick={() => Auth.federatedSignIn({ provider: 'Google' })}
            style={{
              backgroundColor: '#4285F4', // Google blue
              color: '#fff', // Text color white
              textTransform: 'none', // Removes uppercase transformation
              fontWeight: 'bold', // Makes the font bold
              fontSize: 16, // Set font size
            }}
          >
            Sign in with Google
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

