import React, { useState, useEffect } from 'react'
// UI
import axios from 'axios'
import { Box, SvgIcon, Typography } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles(theme => ({
  root: { position: 'relative' },
  upsell_box: {
    borderRadius: 10,
    aspectRatio: '1',
    padding: '12px',
    paddingTop: 40,
    flex: 1,
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    minWidth: 'calc((100% / 2) - 30px)',
    maxWidth: 'calc((100% / 2) - 30px)',

  },
  checkbox: {
    position: 'absolute',
    top: 7,
    right: 7,
    padding: theme.spacing(0.5),
    zIndex: 1,
    '& svg': { fontSize: 15 },
  },
  logo: {
    height: 45,
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
  },
  customIcon: {
    width: '100% !important',
    maxHeight: 102,
    aspectRatio: '1',
    [theme.breakpoints.up('mobile')]: { maxWidth: '65%' },
    [theme.breakpoints.up('tablet')]: { maxWidth: '85%' },
    [theme.breakpoints.up('xtablet')]: { maxWidth: '100%' },
  },
  name: {
    fontSize: '16px !important',
    fontWeight: '400 !important',
    lineHeight: '24px !important',
    minHeight: 48,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-word',
    width: '100%',
    lineClamp: 3,
  },
  editBtn: {
    padding: 0,
    minWidth: 'fit-content',
    fontWeight: 400,
    fontSize: 14,
  },
}))


export default function UpsellIcon({ upsell }) {
  const classes = useStyles()
  const [product, setProduct] = useState(null);


  useEffect(() => {
    if (!upsell) { return }
    console.log("FETCHING PRODUCT")
    axios.get(`https://enso-image-assets.s3.amazonaws.com/products/${upsell}_product.json`).then(r =>
      setProduct(r.data)
    )
  }, [upsell])


  return (
    <div
      className={classes.upsell_box}
      tabIndex={0}
      id={upsell?.product_id ?? undefined}
    >
      <Box display={'flex'} alignItems={'center'} width={'100%'}>
        {(!!product) && <SvgIcon
          viewBox={product.viewBox}
          style={{ fontSize: 100 }}
          className={classes.customIcon}
          color="primary">
          <path d={product.path} />
        </SvgIcon>}
      </Box>
    </div>
  )
}
