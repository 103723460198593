import React, { useState, useEffect } from 'react';
import TemplateList from 'components/TemplateList';
import TemplateEditor from 'components/TemplateEditor';
import { Container } from '@mui/material';
import { API } from 'aws-amplify';

function Templates({ content_spec }) {
  const [selectedStage, setSelectedStage] = useState('prod')
  const [loaded, setLoaded] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [currentTemplate, setCurrentTemplate] = useState(null);

  useEffect(() => {
      console.log('LOADING')
      loadData()
  }, [selectedStage]);


  function loadData(load_metrics=false){
      setLoaded(true)
      API.get('ensoAPI', `/useradmin/content?stage=${selectedStage}&content_type=${content_spec.content_type}`).then((response) => {
        setTemplates(response?.data)
      })
  }



  const handleEdit = (index) => {
    setCurrentTemplate({ ...templates[index], index }); // Pass index to track changes
  };

  const handleAdd = () => {
    const newTemplate = {
      title: "",
      launchdarkly_feature_flag_key: "",
      content: {},
      description: "",
      how_it_works: "",
      icon_url: "",
      picture_url: "",
      location: {
        countries: [],
        cities: [],
        regions: [],
        postal_codes: []
      }
    };
    setCurrentTemplate({ ...newTemplate, index: -1 }); // Index -1 for new template
  };

  const handleSave = (newTemplate) => {
    let body = {
      template: newTemplate,
      stage: selectedStage,
      template_type: content_spec.content_type
    }
    API.post('ensoAPI', `/useradmin/content`, {body: body}).then((response) => {
        if (currentTemplate.index >= 0) {
        // Update existing template
        const updatedTemplates = templates.map((template, index) =>
          index === currentTemplate.index ? newTemplate : template
        );
        setTemplates(updatedTemplates);
      } else {
        // Add new template
        setTemplates([...templates, newTemplate]);
      }
      setCurrentTemplate(null); // Clear current template after save
    }).catch(
      e => alert(`REQUEST FAILED ${e}`)
    )
  };

  const handleBack = () => {
    setCurrentTemplate(null);  // Clear the current template and go back to list
  };

  return (
    <Container style={{maxHeight: 'calc(100vh - 100px)'}}>
      {currentTemplate ? (
        <TemplateEditor content_spec={content_spec} template={currentTemplate} onSave={handleSave} onBack={handleBack}/>
      ) : (
        <TemplateList  content_spec={content_spec} loading={!loaded} templates={templates} onEdit={handleEdit} onAdd={handleAdd} />
      )}
    </Container>
  );
}

export default Templates;
