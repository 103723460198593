import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@material-ui/core';

function GenerateGuidebooksButton({ onConfirm }) {
    const [open, setOpen] = useState(false);
    const [password, setPassword] = useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = () => {
        // API call logic here
        console.log('API Call with password:', password);
        // Close the modal after the API call
        setOpen(false);
        onConfirm()
    };

    return (
        <div>
            <Button variant="contained" onClick={handleClickOpen}>
                Generate Guidebooks
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Confirm Action</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        WARNING: Please ensure you have approval to generate guidebooks before clicking this.
                        <br/>WARNING 2: Each city costs us $5 to generate guidebooks for, please be careful to not exceed our budget.
                        <br/>WARNING 3: Guidebooks takes 15-30 min to generate. DO NOT click the button more than once per Enso account.
                        <br/>Please enter the guidebook password below
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="password"
                        label="Password"
                        type="password"
                        fullWidth
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirm} color="primary" disabled={password != 'genGuidebook4u'}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default GenerateGuidebooksButton;