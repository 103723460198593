import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles(theme => ({
  logo: {
    width: '230px'
  },
  button: {
    width: '210px !important',
    marginBottom: 10
  },
  login_col: {
    display: 'grid',
    overflow: 'auto',
  },
}));


export default function LoginForm({
  loading, handleChange, wrong_user, wrong_pass, handleSubmit, network, email
}) {
  const classes = useStyles();

  const handleKeyUp = (e) => {
    if (["Enter"].includes(e.key))
      handleSubmit(e)
  }

  return (
    <Container fluid>
      <div>
        <div style={{marginTop: '200px'}}>
          {loading && <LinearProgress style={{ position: 'absolute', top: 0, width: '100%' }} />}
          <Grid
            container style={{ margin: '1rem 0' }}
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid style={{marginBottom: 20}} item>
              <img
                alt="Enso Connect logo" className={classes.logo}
                src="https://enso-image-assets.s3.amazonaws.com/enso_blue_logo.svg"
              />            </Grid>
            <Grid item>
              <TextField
                type="username" label="Username" variant="outlined"
                className={"mt-5"}
                value={email}
                onKeyUp={handleKeyUp}
                onChange={handleChange}
                error={wrong_user} id="email"
                helperText={wrong_user ? 'incorrect username' : ' '}
              />
            </Grid>
            <Grid item>
              <TextField
                type="password" label="Password" variant="outlined"
                className={"mt-2"}
                onKeyUp={handleKeyUp}
                onChange={handleChange}
                error={wrong_pass} id="password"
                helperText={wrong_pass ? 'incorrect password' : ' '}
              />
            </Grid>
            <Grid item>
              {network && <div style={{ color: 'red', fontSize: '15px', paddingBottom: '10px' }}>No internet connection.</div>}
            </Grid>
            <Grid item>
              <Button
                disableElevation
                variant="contained" className={classes.button} color="primary"
                onClick={handleSubmit}
              >
                Login
              </Button>
            </Grid>
            <Typography>Please contact the tech team to reset your password</Typography>
          </Grid>
        </div>
      </div>
    </Container>
  );
}


