import React, {useState} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {Button} from '@material-ui/core';
import Accounts from 'routes/Accounts.jsx';
import { makeStyles } from '@material-ui/core/styles';
import Content from 'routes/Content.jsx'
import Templates from 'routes/Templates.jsx'
import {schema} from 'schemas.js'
import Login from 'routes/Login.jsx';
import { Auth, API } from 'aws-amplify';
import DateFnsUtils from '@date-io/date-fns';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { content_config } from 'configuration/specs.js'

import {
  MuiPickersUtilsProvider
} from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
}));


const theme = createTheme({
  palette: {
    primary: { main: '#2f7afb', contrast: '#2f7afb' },
  }
})



export default function BasicExample() {
  const classes = useStyles();
  const [currentUser, setCurrentUser] = useState(false);

  /*function handleSetCurrentUser(account_id) {
    console.log("SETTING CURRENT USER")
    API.get('ensoAPI', `/useradmin/accounts?account_id=${account_id}`).then((response) => {
      setCurrentUser(response.user)
    })
  }*/

  function admin_layout() {
    if (!currentUser){
      Auth.currentAuthenticatedUser()
      .then(data => setCurrentUser(data.username))
    }
    const is_admin = currentUser?.scopes?.accounts == 'write'
    return <div>
     <ThemeProvider theme={theme}>
      <AppBar style={{ background: '#2f7afb' }} color="primary" position="static">
        <Toolbar>
            <Typography variant="h5" className={classes.title}>
              <Link style={{ color: 'inherit', textDecoration: 'inherit', marginRight: 20}} to="/admin/accounts">Accounts</Link>
              <Link style={{ color: 'inherit', textDecoration: 'inherit'}} to="/admin/content">Content</Link>
            </Typography>
             <Button color="inherit" onClick={() => Auth.signOut()} style={{ marginLeft: 'auto' }}>
              Sign Out
            </Button>
        </Toolbar>
      </AppBar>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Switch>
        <Route path="/admin/accounts">
          <Accounts is_admin={is_admin}/>
        </Route>
        {content_config.map(config => 
          <Route path={`/admin/${config.content_type}`}>
            <Templates content_spec={config}/>
          </Route>
        )}
        <Route path="/admin/content">
          <Content/>
        </Route>
      </Switch>
      </MuiPickersUtilsProvider>
      </ThemeProvider>
    </div>
  }

  const auth_layout = <Switch>
    <Route exact path="/auth/login">
      <Login setCurrentUser={s => setCurrentUser(s)}/>
    </Route>
  </Switch>


  return (
    <Router>
        <Switch>
          <Route path="/admin" render={admin_layout}/>
          <Route path="/auth" render={() => auth_layout}/>
          <Redirect from="/" to="/auth/login" />
        </Switch>
    </Router>
  );
}