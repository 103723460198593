import React, { useState } from 'react';
import {
  TextField,
  Button,
  Box,
  Typography,
  Grid
} from '@mui/material';
import Form from '@rjsf/material-ui';
import { API } from 'aws-amplify';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import validator from '@rjsf/validator-ajv8';
import CopyToClipboard from './CopyToClipboard.jsx';

function TemplateEditor({ template, content_spec, onSave, onBack }) {
  const [editedTemplate, setEditedTemplate] = useState(template);
  const [loading, setLoading] = useState(false);
  const [refreshName, setRefreshName] = useState(null);

  const handleChange = (field, value) => {
    setEditedTemplate(prev => ({ ...prev, [field]: value }));
  };

  const handleContentChange = (key, value) => {
    setEditedTemplate(prev => ({
      ...prev,
      content: { ...prev.content, [key]: value }
    }));
  };

  function getTemporaryAuth(){
    let data = {
      enso_key: "927dd90b-d00f-490a-98d9-e9ff25019772",
      action: 'temporary_login',
      stage: "prod"
    };
    API.post('ensoAPI', '/useradmin', { body: data }).then((response) => {
      window.open('https://' + response);
    }).catch(error => {
      console.error("Error fetching temporary auth:", error);
    });
  }

  function refreshContent(){
    let data = {
      enso_key: "927dd90b-d00f-490a-98d9-e9ff25019772",
      action: 'temporary_login',
      stage: "prod",
      template_type: content_spec.content_type,
      resource_name: refreshName
    };
    const queryString = new URLSearchParams(data).toString();
    API.get('ensoAPI', `/useradmin/content/sync?${queryString}`).then((response) => {
      setEditedTemplate({ ...editedTemplate, content: response.data });
    }).catch(error => {
      console.error("Error refreshing content:", error);
    });
  }

  function getCustomContent() {
    switch(content_spec.content_type) {
      case 'addon_services':
        return (
          <div>
            {!!editedTemplate.markdown_summary && <CopyToClipboard label='Copy PMS Text Summary' textToCopy={editedTemplate.markdown_summary} />}
          </div>
        );
      default:
        return null;
    }
  }

  return (
    <Box sx={{ mt: 4 }}>
      <Box sx={{ mt: 2, mb: 2, display: 'flex', gap: 1 }}>
        <Button variant="outlined" color="primary" onClick={onBack} sx={{ padding: '4px 8px' }}>
          Back
        </Button>
      </Box>

      {getCustomContent()}

      <Form
        schema={content_spec.form_schema}
        onChange={v => setEditedTemplate(v.formData)}
        formData={editedTemplate}
        onError={errors => console.log("GOT ERROR", errors)}
        onSubmit={() => onSave(editedTemplate)}
        validator={validator}
        uiSchema={{
          'ui:spacing': 2,
          'ui:options': {
            label: false,
            compact: true
          }
        }}
        FieldTemplate={({ children, className, ...props }) => (
          <div className={className} style={{ marginBottom: '8px' }}>
            {children}
          </div>
        )}
        style={{ padding: '0 16px' }} // Reducing padding around the form
      />

      {!!template.content && (
        <div style={{ marginTop: 10 }}>
          <Typography variant='h5' style={{ marginTop: 10 }}>Edit Template Content</Typography>

          <Grid container spacing={1} sx={{ marginTop: 1, marginBottom: 1 }}>
            <Grid item xs={6}>
              <TextField
                label={`Name of ${content_spec?.title} to update`}
                value={refreshName}
                size="small"
                margin="dense"
                style={{ width: '100%' }}
                onChange={e => setRefreshName(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                onClick={refreshContent}
                disabled={!refreshName}
                style={{ padding: '4px 8px', marginRight: 10 }}
              >
                Refresh Content
              </Button>
            </Grid>
          </Grid>

          <Button variant="contained" color="primary" onClick={getTemporaryAuth} sx={{ padding: '4px 8px', marginBottom: 2 }}>
            Preview Content  
          </Button>

          <JSONInput
            id="a_unique_id"
            placeholder={editedTemplate.content}
            locale={locale}
            height="300px"
            width="100%"
            style={{ fontSize: '12px', lineHeight: '16px' }}
          />
        </div>
      )}
    </Box>
  );
}

export default TemplateEditor;
