import React, { useState, useEffect, Fragment } from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  Tab,
  Modal,
  Box,
  TextField,
  List,
  ListItem,
  Tabs,
  Tooltip,
  Typography,
  InputAdornment,
  CircularProgress
} from '@material-ui/core/';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {timeDifference} from 'helpers.js'
import { API } from 'aws-amplify';
import { currencyEnum } from 'configuration/specs.js'
import { makeStyles } from '@material-ui/core/styles';
// Components
import PlanEditor from 'components/PlanEditor.jsx';
import DiscountEditor from 'components/DiscountEditor.jsx';
import GenerateGuidebooksButton from 'components/GenerateGuidebooksButton.jsx';
import AccountsTable from 'components/AccountsTable.jsx';


const STAGES = ['master', 'qa', 'prod']
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxHeight: '80%',
  overflowY: 'scroll',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


const useStyles = makeStyles((theme) => ({
  row: {
    padding: 15
  },
}));

const AVAILABLE_CURRENCIES = [
    'CAD', 'USD', 'EUR', 'GBP', 'AUD', 'NZD'
]



export default function Accounts({is_admin}){
    const [loaded, setLoaded] = useState(false);
    const [accounts, setAccounts] = useState(false);
    const [taxLabel, setTaxLabel] = useState(null);
    const [taxPercent, setTaxPercent] = useState(null);
    const [overrideTax, setOverrideTax] = useState(false);
    const [selectedStage, setSelectedStage] = useState('prod')
    const [totals, setTotals] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState(null)
    const [qwilrPayment, setQwilrPayment] = useState({});
    const [selectedAccount, setSelectedAccount] = useState(false)
    const [newAccountDetails, setNewAccountDetails] = useState({name: '', email: ''});
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setSelectedPlan(null);
        setOpen(false)
    }

    useEffect(() => {
        console.log('LOADING')
        loadData()
    }, [selectedStage]);


    function loadData(load_metrics=false){
        setLoaded(true)
        API.get('ensoAPI', `/useradmin?stage=${selectedStage}&load_metrics=${load_metrics}`).then((response) => {
          setAccounts(response?.roots)
          if (load_metrics){
              setTotals({
                collected: response.collected,
                upcoming: response.upcoming,
                suspended: response.suspended
              })
            }
        })
    }

    function getSelectedAccount(enso_key){
        setLoaded(true)
        API.get('ensoAPI', `/useradmin?enso_key=${enso_key}&stage=${selectedStage}`).then((response) => {
          setSelectedAccount(response)
        })
    }




    function createAccount(){
        setLoaded(true)
        let params = {...newAccountDetails, stage: selectedStage}
        API.post('ensoAPI', `/useradmin/accounts`, {body: params}).then((response) => {
            console.log("GTO NEW ACCOUNT RESPONSE", response)
            setAccounts([...accounts, response])
            setNewAccountDetails({name: '', email: ''})
            handleClose()
        })
    }

    function updateAccount(newAccount){
        let body = {
            ...newAccount,
            enso_key: selectedAccount.root.enso_key,
            stage: selectedStage
        }
        console.log("GOT BODY", body)
        API.patch('ensoAPI', '/useradmin/accounts', {body: body}).then(response => {
            handleClose();
            getSelectedAccount(selectedAccount.root.enso_key)
        })
    }

    function generateGuidebooks(){
        let body = {
            generate_guidebooks: {'a': true},
            enso_key: selectedAccount.root.enso_key,
            stage: selectedStage
        }
        console.log("GOT BODY", body)
        API.patch('ensoAPI', '/useradmin/accounts', {body: body}).then(response => {
            alert('Guidebook are generating, please do not click the button for the next 15 - 30 min')
            getSelectedAccount(selectedAccount.root.enso_key)
        })
    }

    function updateBillingSettings(){
        updateAccount({
            currency: selectedAccount.billing.currency,
            pay_by: selectedAccount.root.pay_by,
            tier: selectedAccount.root.plan
            //interval: selectedAccount.interval,
        })
    }


    function savePlan(plan){
        let body = {
            enso_key: selectedAccount.root.enso_key,
            subscription: plan
        }
        API.patch('ensoAPI', '/useradmin/accounts', {body: body}).then(response => {
            handleClose();
            getSelectedAccount(selectedAccount.root.enso_key)
        })
    }


    function saveFirstbill(quantity, interval){
        let body = {
            enso_key: selectedAccount.root.enso_key,
            first_bill_quantity: quantity,
            first_bill_interval: interval
        }
        API.patch('ensoAPI', '/useradmin/accounts', {body: body}).then(response => {
            handleClose();
            getSelectedAccount(selectedAccount.root.enso_key)
        })
    }


    function getTemporaryAuth(){
        let data = {
            enso_key: selectedAccount.root.enso_key,
            action: 'temporary_login',
            stage: selectedStage
        }
        API.post('ensoAPI', '/useradmin', {body: data}).then((response) => {
          window.open('https://' + response);
        })
    }


    function setBillingProperties(field, val){
        setSelectedAccount({
            ...selectedAccount, 
            billing: {
                ...selectedAccount.billing,
                properties: {
                    ...selectedAccount.billing.properties,
                    [field]: val
                }
            }
        })
    }

    function saveQwilrDeal(){
        let payload = {qwilr_email: qwilrPayment.qwilr_email}
        if (!!qwilrPayment.value){
            payload = {...payload, subscription: {
                ...selectedAccount.available_plans?.find(p => p.subscription_id === 'nso'),
                monthly_discount: {
                    name: 'Qwilr pre-payment',
                    duration_in_months: parseInt(qwilrPayment.duration_in_months),
                    applied_on: Date.now(),
                    value: parseFloat(qwilrPayment.value)
                }
            }}
        }
        updateAccount(payload)
    }


    function editPlanButton(b, active=false){
        return <div style={{display: 'flex', alignItems: 'center', width: '100%', marginTop: 10}}>
            <div style={{flex: 1}}><Typography>{b.name}</Typography></div>
            {active && <Button disabled style={{backgroundColor: 'lightgreen', marginLeft: 5, color: 'black', padding: 3, borderRadius: 5, fontSize: 13}}>ACTIVE</Button>}
            {(b.tiers[0].value != 0.01) && <div>
                <Button 
                    style={{backgroundColor: 'lightblue', marginLeft: 5, padding: 3, borderRadius: 5, fontSize: 13}} 
                    onClick={() => {setOpen('edit_plan'); setSelectedPlan(b)}}
                >
                    From ${b.tiers[0].value}
                </Button>
                {(b.subscription_id == 'nso') && <Button 
                    onClick={() => {setOpen('edit_discount'); setSelectedPlan(b)}}
                    style={{backgroundColor: 'lightgrey', marginLeft: 5, padding: 3, borderRadius: 5, fontSize: 13}} 
                >
                    {!!b.monthly_discount 
                        ? `${b.monthly_discount.value}$ off for ${b.monthly_discount.duration_in_months} months`
                        : 'No discount'
                    }
                </Button>}
            </div>}
        </div>
    }


    const TaxPanel = () => {
        return <>
            {(selectedAccount?.billing?.properties?.qwilr_email !== false) && 
            (selectedAccount?.root?.plan == 'new') && <Card style={{maxWidth: 600}}>
                <CardContent>
                    <Typography style={{marginBottom: 10}} variant='h5'>Link Qwilr Deal</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField 
                                label='Stripe email' fullWidth
                                value={qwilrPayment.qwilr_email}
                                onChange={e => setQwilrPayment({...qwilrPayment, qwilr_email: e.target.value})}
                            />
                            <Typography variant='body2'>Enter the user's email as it appears in Stripe</Typography>
                            <TextField 
                                label='Monthly Pre-payment (Optional)'
                                value={qwilrPayment.value} fullWidth
                                type='number'
                                onChange={e => setQwilrPayment({...qwilrPayment, value: e.target.value})}
                            />
                            <Typography variant='body2'>Enter the monthly value of the Qwilr prepayment (i.e. total prepayment / number of months prepaid)</Typography>  
                            <TextField 
                                label='Months remaining (Optional)'
                                type='number' fullWidth
                                value={qwilrPayment.duration_in_months}
                                onChange={e => setQwilrPayment({...qwilrPayment, duration_in_months: e.target.value})}
                            />
                            <Typography variant='body2'>Enter the number of prepaid months remaining</Typography>  
                        </Grid>
                    </Grid>
                    <div style={{display: 'flex', marginTop: 5}}>
                        <div style={{flex: 1}}/>
                        <div>
                            <Button style={{marginRight: 5}} onClick={(e) => updateAccount({qwilr_email: false})} variant='contained'>
                                Dismiss
                            </Button>
                            <Button 
                                variant='contained' 
                                onClick={() => saveQwilrDeal()} 
                                color='primary'
                                disabled={!qwilrPayment.qwilr_email}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </CardContent>
            </Card>}
            <Card style={{maxWidth: 600, marginTop: 20}}>
            <CardContent>
            <div style={{display: 'flex'}}>
                <div style={{flex: 1}}>
                    <Typography style={{marginBottom: 10}} variant='h5'>Billing</Typography>
                </div>
                <Link target="_blank" href={`https://dashboard.stripe.com/customers/${selectedAccount.billing.integration_customer_id}`}>View in Stripe</Link>
            </div>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        label="Billing Start Date"
                        value={selectedAccount.root?.pay_by}
                        onChange={(newValue) => setSelectedAccount({...selectedAccount, root: {...selectedAccount.root, pay_by: new Date(newValue).getTime()}})}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                    <InputLabel color='secondary'>Account Type</InputLabel>
                    <Select
                        value={selectedAccount?.root?.plan}
                        onChange={(e) => setSelectedAccount({...selectedAccount, root: {...selectedAccount.root, plan: e.target.value}})}
                    >
                        {(['free', 'new'].includes(selectedAccount?.root?.plan)) && <MenuItem value={'new'}>Onboarding</MenuItem>}
                        <MenuItem disabled={(selectedAccount?.root?.plan !== 'paid')} value={'paid'}>Paying</MenuItem>
                        <MenuItem value={'free'}>Free/Custom Billing</MenuItem>
                        <MenuItem disabled={(selectedAccount?.root?.plan !== 'sus')} value={'sus'}>Suspended</MenuItem>
                        <MenuItem value={'cncl'}>Subscription cancelled - Client still live</MenuItem>
                        <MenuItem value={'off'}>Subscription cancelled - Offboarded</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={6}>
                    <InputLabel color='secondary'>Billing Interval</InputLabel>
                    <Select
                        disabled
                        value={selectedAccount?.interval}
                        onChange={(e) => updateAccount({interval: e.target.value})}
                    >
                        {['month', 'quarter', 'year'].map(interval => <MenuItem value={interval}>{interval}</MenuItem>)}
                    </Select>
                </Grid>
                <Grid item xs={6}>
                    <InputLabel color='secondary'>Currency</InputLabel>
                    <Select
                        value={selectedAccount?.billing?.currency}
                        onChange={(e) => setSelectedAccount({...selectedAccount, billing: {...selectedAccount.billing, currency: e.target.value}})}
                    >
                        {AVAILABLE_CURRENCIES.map(interval => <MenuItem value={interval}>{interval}</MenuItem>)}
                    </Select>
                </Grid>
                {/*<Grid item xs={6}>
                    <TextField 
                        label='Custom tax label' type='number' 
                        value={taxLabel} 
                        placeholder='i.e. HST'
                        onChange={e => setTaxLabel(e.target.value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField 
                        label='Custom tax %' type='number' 
                        value={taxPercent}
                        placholder='i.e. 13%'
                        onChange={e => setTaxPercent(e.target.value)}
                    />
                </Grid>*/}
            </Grid>
            <div style={{display: 'flex', marginTop: 5}}>
                <div style={{flex: 1}}/>
                <Button variant='contained' color='primary' onClick={() => updateBillingSettings()}>SAVE</Button>
            </div>
            </CardContent>
            </Card>
        </>
    }

    function SelectedAccountPanel(){
        let created = new Date(selectedAccount.root.created_at).toLocaleString()
        let info_rows = [
            ['Created at', created],
            ['enso_key', selectedAccount.root.enso_key],
            ['# listings', selectedAccount.profile.num_listing]
        ]
        return <Card style={{width: '100%', height: '100%', overflow: 'scroll', padding: 15}}>
                <Box className={classes.row}>
                    <div style={{display: 'flex'}}>
                        <div style={{flex: 1}}>
                            <Typography variant='h4'>{selectedAccount.root.name}</Typography>
                        </div>
                        <Button onClick={() => setSelectedAccount(false)}>Close</Button>
                    </div>
                  <Button variant='contained' onClick={() => getTemporaryAuth()}>Temporary Login</Button>
                </Box>
                <Box>
                    {info_rows.map(i => (!!i[1]) ? <Box className={classes.row}>
                        <InputLabel>{i[0]}</InputLabel>
                        <Typography>{i[1]}</Typography>
                    </Box> : null)}
                </Box>
                {TaxPanel()}
                <Card style={{maxWidth: 600, marginTop: 20}}><CardContent>
                    <Typography variant='h5'>Customize Pricing</Typography>
                    <List aria-label="main mailbox folders">
                    {selectedAccount.active_plans?.map(b => 
                        editPlanButton(b, true)
                    )}
                    {selectedAccount.available_plans?.map(b => 
                        editPlanButton(b)
                    )}
                    </List>
                </CardContent></Card>
              <Card style={{maxWidth: 600, marginTop: 20}}><CardContent>
                <Typography variant='h5'>Content</Typography>
                <List aria-label="main mailbox folders">
                {selectedAccount.guidebooks?.map(b => 
                   <div>{b.city} - {b.guidebooks_available ? <span style={{color: 'green'}}>AVAILABLE</span> : <span style={{color: 'red'}}>n/a</span>}</div>
                )}

                </List>
                <GenerateGuidebooksButton onConfirm={() => generateGuidebooks()} />
            </CardContent></Card>
                <Card style={{maxWidth: 600, marginTop: 20}}><CardContent>
                    <Typography variant='h5' style={{marginTop: 20}}>Users</Typography>
                    <List>
                        {selectedAccount.root.enso_users.map(u => 
                            <ListItem>
                                {u.name}
                            </ListItem>

                        )}
                    </List>
                </CardContent></Card>
            </Card>
    }

    const LoadingPage = () => {
        return <CircularProgress/>
    }

    function AccountsList() {
        return <Fragment>
            <Grid container style={{height: '100%'}} >
                <Grid item xs={selectedAccount ? 6 : 12} style={{height: '100%'}}>
                <AccountsTable
                    totals={totals} 
                    createAccount={handleOpen} 
                    loading={!accounts} 
                    accounts={accounts}
                    selectAccount={a => getSelectedAccount(a.row.enso_key)}
                    loadMetrics={a => loadData(true)}
                />
                </Grid>
                {selectedAccount && <Grid item xs={6} style={{height: '100%', padding: 15}} >
                    {SelectedAccountPanel()}
                </Grid>}
            </Grid>
        </Fragment>
    }


    const NewAccountPanel = () => {
        return <>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Create New Account
            </Typography>
            <div style={{marginBottom: 20}}>
            <TextField label='name' onChange={e => setNewAccountDetails({...newAccountDetails, name: e.target.value})}/>
            <TextField label='email' onChange={e => setNewAccountDetails({...newAccountDetails, email: e.target.value})}/>
            </div>
            <Button variant='contained' onClick={() => createAccount()}>Create</Button>
        </>
    }


    const ModalContents = () => {
        switch(open){
            case 'start_subscription':
                return <div>
                    <div>
                    DO NOT enable billing until 
                    - you have set the pre-payment bill
                    - you have set the correct plan prices
                    </div>
                    <Button color='primary' variant='contained'>Enable</Button>
                </div>
            case 'edit_discount':
                return <DiscountEditor savePlan={plan => updateAccount({subscription: plan})} currency={selectedAccount?.billing?.currency} newPlan={selectedPlan}/>
            default:
                return selectedPlan ? 
                <PlanEditor savePlan={plan => updateAccount({subscription: plan})} currency={selectedAccount?.billing?.currency} newPlan={selectedPlan}/>
                : <NewAccountPanel/>
        }
    }

    return(<Fragment>
        <div style={{height: 'calc(100vh - 155px)', padding: 20}}>
        <Modal
          open={open}
          onClose={handleClose}
        >
            <Box sx={style}>
                <ModalContents/>
            </Box>
        </Modal>
        {AccountsList()}  
        </div>
    </Fragment>);

}