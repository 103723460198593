import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import Amplify from '@aws-amplify/core';
import { Auth } from "aws-amplify";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

let isLocalHost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'



Amplify.configure({
  Auth: {
    region: 'us-east-1',
    //userPoolId: process.env.REACT_APP_USER_POOL_ID,
    //identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    //userPoolWebClientId: process.env.REACT_APP_APP_CLIENT_ID
    userPoolId: 'us-east-1_B3mEd28KI', // Your Cognito User Pool ID
    userPoolWebClientId: '4odghg18sbjlg25t86apevqh1f', // Your Cognito User Pool App Client ID
    oauth: {
      domain: 'ensotools.auth.us-east-1.amazoncognito.com', // Your Cognito domain
      scope: ['email', 'profile', 'openid'], // Scopes for Google authentication
      redirectSignIn: isLocalHost ? 'http://localhost:3000/admin/accounts' : "https://tools.ensoconnect.com/admin/accounts",
      redirectSignOut: isLocalHost ? 'http://localhost:3000/' : "https://tools.ensoconnect.com",
      responseType: 'code' // 'code' for PKCE
    }
  },
  API: {
    endpoints: [
      {
        name: "ensoAPI",
        endpoint: process.env.REACT_APP_API_URL,
        region: 'us-east-1',
        custom_header: async () => ({ 
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        })
      }
    ]
  }
});


 
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
