// src/components/TemplateList.js
import React from 'react';
import { List, ListItem, ListItemText, Button, TextField, Box, CircularProgress } from '@mui/material';
import { useHistory } from "react-router-dom";
import {cardsData} from 'configuration/config.js'
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import UpsellIcon from 'components/UpsellIcon.jsx';



function TemplateList({ templates, content_spec, onEdit, onAdd, loading }) {
  let history = useHistory();
  let id_field = content_spec.columns[0].field;
  console.log("GOT ID FIELD", id_field)
  const [searchTerm, setSearchTerm] = React.useState('');

  const enhanceColumns = (columns) => {
      return columns.map(column => {
          if (column.type === 'icon') {
              return {
                  ...column,
                  renderCell: (params) => (
                      <img src={params.value} alt="icon" style={{ height: 30 }} />
                  )
              };
          }
          if (column.type === 'upsell_icon') {
              return {
                  ...column,
                  renderCell: (params) => (
                      <UpsellIcon upsell={params.value} />
                  )
              };
          }
          return column;
      });
  };

  const filteredTemplates = templates.filter((account) => {
    return account[id_field].toLowerCase().includes(searchTerm.toLowerCase())
  });


  return (
    <Box sx={{mt: 4}} style={{height: '100%'}}>
      <div>
        <Button variant="outlined" sx={{mr: 1}} color="primary" onClick={() => history.push('/admin/content')}>
          Back
        </Button>
        <TextField
          label="Search templates..."
          variant="outlined"
          value={searchTerm}
          fullWidth
          sx={{mr: 1}}
          size='small'
          style={{maxWidth: 300}}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button variant="contained" color="primary" onClick={onAdd}>
          Add New Template
        </Button>
      </div>
      <DataGrid
        style={{height: 'calc(100vh - 155px)', overflowY: 'scroll', marginTop: 10}}
        rows={filteredTemplates}
        loading={loading}
        getRowId={(row) => row[id_field]}
        columns={enhanceColumns(content_spec.columns)}
        onRowClick={(params) => onEdit(templates.findIndex(t => t[id_field] === params.row[id_field]))}
        components={{
          Toolbar: GridToolbar,
        }}
      />
    </Box>
  );
}

export default TemplateList;
