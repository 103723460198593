import React from 'react';
import { Card, CardContent, Typography, Grid, Container } from '@mui/material';
import { useHistory } from "react-router-dom";
import { content_config } from 'configuration/specs.js'


function CardGrid() {
    let history = useHistory();

    return (
        <Container>
        <Grid container spacing={4} style={{paddingTop: 30}}>
            {content_config.map((card, index) => (
                <Grid item key={index} xs={12} sm={6} md={4}>
                    <Card sx={{ cursor: 'pointer' }} onClick={() => history.push(`/admin/${card.content_type}`)}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                {card.title}
                            </Typography>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                {card.subtitle}
                            </Typography>
                            {/*<Typography variant="body2">
                                Number of items: {card.count}
                            </Typography>*/}
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
        </Container>
    );
}

export default CardGrid;
