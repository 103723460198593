import React from 'react';
import { Button, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useState } from 'react';

const CopyToClipboard = ({ textToCopy, label='Copy' }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(textToCopy).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset the state after 2 seconds
    });
  };

  return (
    <Tooltip title={copied ? 'Copied!' : 'Copy to clipboard'} placement="top" arrow>
      <Button
        variant="contained"
        color="primary"
        onClick={handleCopy}
        startIcon={<ContentCopyIcon />}
      >
        {label}
      </Button>
    </Tooltip>
  );
};

export default CopyToClipboard;
