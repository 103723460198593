

export const listing_fields = [
    "wifi_network",
    "wifi_password",
    "checkin_time",
    "checkout_time",
    "name",
    "new_tags",
    "names",
    "description",
    "short_description",
    "rules",
    "agreement",
    "address"
]

export const content_config = [
    {
        "title": "PMS & 3rd Party Integrations",
        "subtitle": "Configure content and supported features for 3rd party integrations",
        "content_type": "addon_services",
        "form_schema": {
            "title": "IntegrationSpecModel",
            "type": "object",
            "properties": {
                "title": {
                    "title": "Title",
                    "type": "string"
                },
                "service": {
                    "$ref": "#/definitions/ServiceEnum"
                },
                "provider": {
                    "$ref": "#/definitions/ProviderEnum"
                },
                "icon": {
                    "title": "Icon",
                    "type": "string"
                },
                "picture_url": {
                    "title": "Picture Url",
                    "type": "string"
                },
                "details": {
                    "title": "Details",
                    "type": "string"
                },
                "how_it_works": {
                    "title": "How It Works",
                    "type": "string"
                },
                "includes": {
                    "title": "Includes",
                    "default": [],
                    "type": "array",
                    "items": {
                        "$ref": "#/definitions/IncludedFeatureModel"
                    }
                },
                "signup_type": {
                    "title": "Signup Type",
                    "enum": [
                        "seam",
                        "redirect",
                        "api_key"
                    ],
                    "type": "string"
                },
                "signup_text": {
                    "title": "Signup Text",
                    "type": "string"
                },
                "signup_fields": {
                    "title": "Signup Fields",
                    "default": {},
                    "type": "object",
                    "additionalProperties": {
                        "$ref": "#/definitions/SigupFieldModel"
                    }
                },
                "policies": {
                    "title": "Policies",
                    "default": [],
                    "type": "array",
                    "items": {
                        "type": "array",
                        "items": {
                            "type": "string"
                        }
                    }
                },
                "supported_features": {
                    "$ref": "#/definitions/PMSFormProperties"
                }
            },
            "required": [
                "service",
                "provider"
            ],
            "definitions": {
                "ServiceEnum": {
                    "title": "ServiceEnum",
                    "description": "An enumeration.",
                    "enum": [
                        "obj",
                        "act",
                        "pms",
                        "cb",
                        "msg",
                        "phn",
                        "sl",
                        "kc",
                        "ast",
                        "pay",
                        "ver",
                        "pr",
                        "bk",
                        "ls",
                        "sms",
                        "billing",
                        "groups",
                        "exp"
                    ],
                    "type": "string"
                },
                "ProviderEnum": {
                    "title": "ProviderEnum",
                    "description": "Service providers",
                    "enum": [
                        "partee",
                        "flexipass",
                        "autohost",
                        "superhog",
                        "chekin",
                        "salto",
                        "interphone",
                        "august",
                        "schlage",
                        "smart",
                        "amazon",
                        "keycafe",
                        "stripe",
                        "twilio",
                        "whatsapp",
                        "nuki",
                        "igloo",
                        "yale",
                        "minut",
                        "ttlock",
                        "kwikset",
                        "chatgpt",
                        "4suites",
                        "wyze",
                        "dormakaba_oracode",
                        "lockly",
                        "noiseaware",
                        "ab",
                        "abnb",
                        "vrbo",
                        "gy",
                        "bks",
                        "mews",
                        "ha",
                        "ru",
                        "b24",
                        "ba",
                        "cb",
                        "hfl",
                        "ifr",
                        "impl",
                        "lvnd",
                        "myvr",
                        "opra",
                        "smrt",
                        "stay",
                        "st",
                        "trk",
                        "lg",
                        "lmpm",
                        "lt",
                        "htf",
                        "cs",
                        "ownrz",
                        "avt"
                    ],
                    "type": "string"
                },
                "IncludedFeatureModel": {
                    "title": "IncludedFeatureModel",
                    "type": "object",
                    "properties": {
                        "header": {
                            "title": "Header",
                            "type": "string"
                        },
                        "subheader": {
                            "title": "Subheader",
                            "type": "string"
                        }
                    },
                    "required": [
                        "header"
                    ]
                },
                "SigupFieldModel": {
                    "title": "SigupFieldModel",
                    "type": "object",
                    "properties": {
                        "name": {
                            "title": "Name",
                            "type": "string"
                        }
                    },
                    "required": [
                        "name"
                    ]
                },
                "FeatureSupportEnum": {
                    "title": "FeatureSupportEnum",
                    "description": "An enumeration.",
                    "enum": [
                        "supported",
                        "unsupported by Enso",
                        "unsupported by PMS"
                    ],
                    "type": "string"
                },
                "BookingFieldsSpecModel": {
                    "title": "BookingFieldsSpecModel",
                    "type": "object",
                    "properties": {
                        "booking_multi_guest_support": {
                            "title": "Booking Multi Guest Support",
                            "enum": [
                                "one_guest_multiple_bookings",
                                "one_guest_one_booking",
                                "unknown"
                            ],
                            "type": "string"
                        },
                        "booking_multi_guest_support_notes": {
                            "title": "Booking Multi Guest Support Notes",
                            "type": "string"
                        },
                        "checkin_date": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "checkin_date_notes": {
                            "title": "Checkin Date Notes",
                            "type": "string"
                        },
                        "checkin_time": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "checkin_time_notes": {
                            "title": "Checkin Time Notes",
                            "type": "string"
                        },
                        "checkout_date": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "checkout_date_notes": {
                            "title": "Checkout Date Notes",
                            "type": "string"
                        },
                        "checkout_time": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "checkout_time_notes": {
                            "title": "Checkout Time Notes",
                            "type": "string"
                        },
                        "currency": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "currency_notes": {
                            "title": "Currency Notes",
                            "type": "string"
                        },
                        "device_codes": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "device_codes_notes": {
                            "title": "Device Codes Notes",
                            "type": "string"
                        },
                        "fees": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "fees_notes": {
                            "title": "Fees Notes",
                            "type": "string"
                        },
                        "guest_country": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "guest_country_notes": {
                            "title": "Guest Country Notes",
                            "type": "string"
                        },
                        "guest_email": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "guest_email_notes": {
                            "title": "Guest Email Notes",
                            "type": "string"
                        },
                        "guest_phone": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "guest_phone_notes": {
                            "title": "Guest Phone Notes",
                            "type": "string"
                        },
                        "guest_language": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "guest_language_notes": {
                            "title": "Guest Language Notes",
                            "type": "string"
                        },
                        "host_payout_amount": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "host_payout_amount_notes": {
                            "title": "Host Payout Amount Notes",
                            "type": "string"
                        },
                        "inquiries": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "inquiries_notes": {
                            "title": "Inquiries Notes",
                            "type": "string"
                        },
                        "nightly_rate": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "nightly_rate_notes": {
                            "title": "Nightly Rate Notes",
                            "type": "string"
                        },
                        "number_of_adults": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "number_of_adults_notes": {
                            "title": "Number Of Adults Notes",
                            "type": "string"
                        },
                        "number_of_children": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "number_of_children_notes": {
                            "title": "Number Of Children Notes",
                            "type": "string"
                        },
                        "number_of_pets": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "number_of_pets_notes": {
                            "title": "Number Of Pets Notes",
                            "type": "string"
                        },
                        "ota_confirmation_code": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "ota_confirmation_code_notes": {
                            "title": "Ota Confirmation Code Notes",
                            "type": "string"
                        },
                        "payment_status": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "payment_status_notes": {
                            "title": "Payment Status Notes",
                            "type": "string"
                        },
                        "status": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "status_notes": {
                            "title": "Status Notes",
                            "type": "string"
                        },
                        "tags": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "tags_notes": {
                            "title": "Tags Notes",
                            "type": "string"
                        },
                        "taxes": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "taxes_notes": {
                            "title": "Taxes Notes",
                            "type": "string"
                        },
                        "total_guest_paid": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "total_guest_paid_notes": {
                            "title": "Total Guest Paid Notes",
                            "type": "string"
                        },
                        "custom_fields": {
                            "title": "Custom Fields",
                            "type": "array",
                            "items": {
                                "type": "string"
                            }
                        },
                        "all_custom_fields": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "custom_fields_notes": {
                            "title": "Custom Fields Notes",
                            "type": "string"
                        },
                        "dual_sync_fields": {
                            "title": "Dual Sync Fields",
                            "type": "array",
                            "items": {
                                "type": "string"
                            }
                        },
                        "dual_sync_notes": {
                            "title": "Dual Sync Notes",
                            "type": "string"
                        }
                    },
                    "required": [
                        "booking_multi_guest_support",
                        "checkin_date",
                        "checkin_time",
                        "checkout_date",
                        "checkout_time",
                        "currency",
                        "device_codes",
                        "fees",
                        "guest_country",
                        "guest_email",
                        "guest_phone",
                        "guest_language",
                        "host_payout_amount",
                        "inquiries",
                        "nightly_rate",
                        "number_of_adults",
                        "number_of_children",
                        "number_of_pets",
                        "ota_confirmation_code",
                        "payment_status",
                        "status",
                        "tags",
                        "taxes",
                        "total_guest_paid",
                        "custom_fields",
                        "all_custom_fields",
                        "dual_sync_fields"
                    ]
                },
                "ListingFieldsSpecModel": {
                    "title": "ListingFieldsSpecModel",
                    "type": "object",
                    "properties": {
                        "listing_multi_unit_support": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "listing_multi_unit_support_notes": {
                            "title": "Listing Multi Unit Support Notes",
                            "type": "string"
                        },
                        "active_inactive": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "active_inactive_notes": {
                            "title": "Active Inactive Notes",
                            "type": "string"
                        },
                        "amenities": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "amenities_notes": {
                            "title": "Amenities Notes",
                            "type": "string"
                        },
                        "availability": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "availability_notes": {
                            "title": "Availability Notes",
                            "type": "string"
                        },
                        "checkin_directions": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "checkin_directions_notes": {
                            "title": "Checkin Directions Notes",
                            "type": "string"
                        },
                        "checkin_out_times": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "checkin_out_times_notes": {
                            "title": "Checkin Out Times Notes",
                            "type": "string"
                        },
                        "complete_address": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "complete_address_notes": {
                            "title": "Complete Address Notes",
                            "type": "string"
                        },
                        "currency": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "currency_notes": {
                            "title": "Currency Notes",
                            "type": "string"
                        },
                        "description": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "description_notes": {
                            "title": "Description Notes",
                            "type": "string"
                        },
                        "house_rules": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "house_rules_notes": {
                            "title": "House Rules Notes",
                            "type": "string"
                        },
                        "max_guests": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "max_guests_notes": {
                            "title": "Max Guests Notes",
                            "type": "string"
                        },
                        "max_children": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "max_children_notes": {
                            "title": "Max Children Notes",
                            "type": "string"
                        },
                        "name": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "name_notes": {
                            "title": "Name Notes",
                            "type": "string"
                        },
                        "nickname": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "nickname_notes": {
                            "title": "Nickname Notes",
                            "type": "string"
                        },
                        "pet_policy": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "pet_policy_notes": {
                            "title": "Pet Policy Notes",
                            "type": "string"
                        },
                        "smoking_policy": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "smoking_policy_notes": {
                            "title": "Smoking Policy Notes",
                            "type": "string"
                        },
                        "picture": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "picture_notes": {
                            "title": "Picture Notes",
                            "type": "string"
                        },
                        "pictures": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "pictures_notes": {
                            "title": "Pictures Notes",
                            "type": "string"
                        },
                        "property_type": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "property_type_notes": {
                            "title": "Property Type Notes",
                            "type": "string"
                        },
                        "rooms": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "rooms_notes": {
                            "title": "Rooms Notes",
                            "type": "string"
                        },
                        "space": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "space_notes": {
                            "title": "Space Notes",
                            "type": "string"
                        },
                        "tags": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "tags_notes": {
                            "title": "Tags Notes",
                            "type": "string"
                        },
                        "wifi_info": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "wifi_info_notes": {
                            "title": "Wifi Info Notes",
                            "type": "string"
                        },
                        "custom_fields": {
                            "title": "Custom Fields",
                            "type": "array",
                            "items": {
                                "type": "string"
                            }
                        },
                        "all_custom_fields": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "custom_fields_notes": {
                            "title": "Custom Fields Notes",
                            "type": "string"
                        },
                        "dual_sync_fields": {
                            "title": "Dual Sync Fields",
                            "type": "array",
                            "items": {
                                "type": "string"
                            }
                        },
                        "dual_sync_notes": {
                            "title": "Dual Sync Notes",
                            "type": "string"
                        }
                    },
                    "required": [
                        "listing_multi_unit_support",
                        "active_inactive",
                        "amenities",
                        "availability",
                        "checkin_directions",
                        "checkin_out_times",
                        "complete_address",
                        "currency",
                        "description",
                        "house_rules",
                        "max_guests",
                        "max_children",
                        "name",
                        "nickname",
                        "pet_policy",
                        "smoking_policy",
                        "picture",
                        "pictures",
                        "property_type",
                        "rooms",
                        "space",
                        "tags",
                        "wifi_info",
                        "custom_fields",
                        "all_custom_fields",
                        "dual_sync_fields"
                    ]
                },
                "MessageFieldsSpecModel": {
                    "title": "MessageFieldsSpecModel",
                    "type": "object",
                    "properties": {
                        "message_attachments_functionality": {
                            "title": "Message Attachments Functionality",
                            "type": "array",
                            "items": {
                                "type": "string"
                            }
                        },
                        "message_attachments_notes": {
                            "title": "Message Attachments Notes",
                            "type": "string"
                        },
                        "view_message_channels": {
                            "title": "View Message Channels",
                            "type": "array",
                            "items": {
                                "type": "string"
                            }
                        },
                        "view_message_channels_notes": {
                            "title": "View Message Channels Notes",
                            "type": "string"
                        },
                        "message_dual_sync_support": {
                            "$ref": "#/definitions/FeatureSupportEnum"
                        },
                        "message_dual_sync_notes": {
                            "title": "Message Dual Sync Notes",
                            "type": "string"
                        }
                    },
                    "required": [
                        "message_attachments_functionality",
                        "view_message_channels",
                        "message_dual_sync_support"
                    ]
                },
                "PMSFormProperties": {
                    "title": "PMSFormProperties",
                    "type": "object",
                    "properties": {
                        "listing_sync_frequency": {
                            "title": "Listing Sync Frequency",
                            "enum": [
                                "24 hr",
                                "instant"
                            ],
                            "type": "string"
                        },
                        "booking_sync_frequency": {
                            "title": "Booking Sync Frequency",
                            "enum": [
                                "5 min",
                                "instant"
                            ],
                            "type": "string"
                        },
                        "message_sync_frequency": {
                            "title": "Message Sync Frequency",
                            "enum": [
                                "none",
                                "instant",
                                "5 min"
                            ],
                            "type": "string"
                        },
                        "booking_fields_spec": {
                            "$ref": "#/definitions/BookingFieldsSpecModel"
                        },
                        "listing_fields_spec": {
                            "$ref": "#/definitions/ListingFieldsSpecModel"
                        },
                        "message_fields_spec": {
                            "$ref": "#/definitions/MessageFieldsSpecModel"
                        },
                        "api_docs": {
                            "title": "Api Docs",
                            "type": "string"
                        },
                        "next_step": {
                            "title": "Next Step",
                            "type": "string"
                        }
                    },
                    "required": [
                        "listing_sync_frequency",
                        "booking_sync_frequency",
                        "message_sync_frequency",
                        "booking_fields_spec",
                        "listing_fields_spec",
                        "message_fields_spec"
                    ]
                }
            }
        },
        "columns": [
            {
                "field": "title",
                "headerName": "Name",
                "width": 200
            },
            {
                "field": "icon",
                "headerName": "Icon",
                "width": 200,
                "type": "icon"
            },
            {
                "field": "service",
                "headerName": "Service",
                "width": 200
            },
            {
                "field": "provider",
                "headerName": "Provider",
                "width": 200
            }
        ]
    },
    {
        "title": "Guidebooks",
        "subtitle": "Curate your guides",
        "content_type": "guidebook",
        "form_schema": {
            "title": "TemplateModel",
            "type": "object",
            "properties": {
                "title": {
                    "title": "Title",
                    "type": "string"
                },
                "icon_url": {
                    "title": "Icon Url",
                    "type": "string"
                },
                "picture_url": {
                    "title": "Picture Url",
                    "type": "string"
                },
                "how_it_works": {
                    "title": "How It Works",
                    "type": "string"
                },
                "description": {
                    "title": "Description",
                    "type": "string"
                },
                "enabled": {
                    "title": "Enabled",
                    "type": "boolean"
                },
                "launchdarkly_feature_flag_key": {
                    "title": "Launchdarkly Feature Flag Key",
                    "type": "string"
                }
            }
        },
        "columns": [
            {
                "field": "title",
                "headerName": "Name",
                "width": 200
            }
        ]
    },
    {
        "title": "Upsells",
        "subtitle": "Increase your revenue",
        "content_type": "upsell",
        "form_schema": {
            "title": "TemplateModel",
            "type": "object",
            "properties": {
                "title": {
                    "title": "Title",
                    "type": "string"
                },
                "icon_url": {
                    "title": "Icon Url",
                    "type": "string"
                },
                "picture_url": {
                    "title": "Picture Url",
                    "type": "string"
                },
                "how_it_works": {
                    "title": "How It Works",
                    "type": "string"
                },
                "description": {
                    "title": "Description",
                    "type": "string"
                },
                "enabled": {
                    "title": "Enabled",
                    "type": "boolean"
                },
                "launchdarkly_feature_flag_key": {
                    "title": "Launchdarkly Feature Flag Key",
                    "type": "string"
                }
            }
        },
        "columns": [
            {
                "field": "title",
                "headerName": "Name",
                "width": 200
            },
            {
                "field": "description",
                "headerName": "Description",
                "width": 200
            }
        ]
    },
    {
        "title": "Fees",
        "subtitle": "Setup additional fees",
        "content_type": "fee",
        "form_schema": {
            "title": "TemplateModel",
            "type": "object",
            "properties": {
                "title": {
                    "title": "Title",
                    "type": "string"
                },
                "icon_url": {
                    "title": "Icon Url",
                    "type": "string"
                },
                "picture_url": {
                    "title": "Picture Url",
                    "type": "string"
                },
                "how_it_works": {
                    "title": "How It Works",
                    "type": "string"
                },
                "description": {
                    "title": "Description",
                    "type": "string"
                },
                "enabled": {
                    "title": "Enabled",
                    "type": "boolean"
                },
                "launchdarkly_feature_flag_key": {
                    "title": "Launchdarkly Feature Flag Key",
                    "type": "string"
                }
            }
        },
        "columns": [
            {
                "field": "title",
                "headerName": "Name",
                "width": 200
            },
            {
                "field": "description",
                "headerName": "Description",
                "width": 200
            }
        ]
    },
    {
        "title": "Local Services",
        "subtitle": "Explore local offerings",
        "content_type": "local_service",
        "form_schema": {
            "title": "LocalServiceModel",
            "type": "object",
            "properties": {
                "id": {
                    "title": "Id",
                    "type": "string"
                },
                "name": {
                    "title": "Name",
                    "type": "string"
                },
                "url": {
                    "title": "Url",
                    "type": "string"
                },
                "enabled": {
                    "title": "Enabled",
                    "default": true,
                    "type": "boolean"
                },
                "category": {
                    "$ref": "#/definitions/LocalServiceTypeEnum"
                },
                "description": {
                    "title": "Description",
                    "type": "string"
                },
                "launchdarkly_feature_flag_key": {
                    "title": "Launchdarkly Feature Flag Key",
                    "type": "string"
                }
            },
            "required": [
                "id",
                "category"
            ],
            "definitions": {
                "LocalServiceTypeEnum": {
                    "title": "LocalServiceTypeEnum",
                    "description": "An enumeration.",
                    "enum": [
                        "transportation",
                        "luggage",
                        "food_and_beverage",
                        "experiences",
                        "guidebooks",
                        "healthcare",
                        "other",
                        "car_rentals",
                        "airlines",
                        "tickets",
                        "groceries",
                        "laundry",
                        "guides",
                        "gifts",
                        "infants_and_children",
                        "flowers",
                        "activity_equipment",
                        "classes",
                        "shopping"
                    ],
                    "type": "string"
                }
            }
        },
        "columns": [
            {
                "field": "id",
                "headerName": "Icon",
                "width": 200,
                "type": "upsell_icon"
            },
            {
                "field": "name",
                "headerName": "Name",
                "width": 200
            },
            {
                "field": "enabled",
                "headerName": "Enabled",
                "width": 200
            },
            {
                "field": "category",
                "headerName": "Category",
                "width": 200
            }
        ]
    },
    {
        "title": "Images",
        "subtitle": "Manage your images",
        "content_type": "image",
        "form_schema": {
            "title": "PresetImageModel",
            "type": "object",
            "properties": {
                "image_url": {
                    "title": "Image Url",
                    "type": "string"
                },
                "tags": {
                    "title": "Tags",
                    "type": "array",
                    "items": {
                        "type": "string"
                    }
                },
                "resource_types": {
                    "type": "array",
                    "items": {
                        "$ref": "#/definitions/ListingResourceEnum"
                    }
                }
            },
            "required": [
                "image_url",
                "tags",
                "resource_types"
            ],
            "definitions": {
                "ListingResourceEnum": {
                    "title": "ListingResourceEnum",
                    "description": "An enumeration.",
                    "enum": [
                        "gb",
                        "ci",
                        "co",
                        "up",
                        "fee",
                        "cv",
                        "rp",
                        "py",
                        "bd"
                    ],
                    "type": "string"
                }
            }
        },
        "columns": [
            {
                "field": "image_url",
                "headerName": "URL",
                "width": 200
            },
            {
                "field": "resource_type",
                "headerName": "Resource type",
                "width": 200
            }
        ]
    },
    {
        "title": "Experiences",
        "subtitle": "Offer unique experiences",
        "content_type": "experience",
        "form_schema": {
            "title": "ExperienceTemplateModel",
            "type": "object",
            "properties": {
                "title": {
                    "title": "Title",
                    "type": "string"
                },
                "icon_url": {
                    "title": "Icon Url",
                    "type": "string"
                },
                "picture_url": {
                    "title": "Picture Url",
                    "type": "string"
                },
                "experience_type": {
                    "$ref": "#/definitions/ExperienceTypeEnum"
                },
                "how_does_it_work": {
                    "title": "How Does It Work",
                    "type": "string"
                },
                "subtitle": {
                    "title": "Subtitle",
                    "type": "string"
                },
                "description": {
                    "title": "Description",
                    "type": "string"
                },
                "enabled": {
                    "title": "Enabled",
                    "type": "boolean"
                },
                "launchdarkly_feature_flag_key": {
                    "title": "Launchdarkly Feature Flag Key",
                    "type": "string"
                }
            },
            "required": [
                "experience_type"
            ],
            "definitions": {
                "ExperienceTypeEnum": {
                    "title": "ExperienceTypeEnum",
                    "description": "An enumeration.",
                    "enum": [
                        "msg",
                        "key",
                        "hid",
                        "cmp",
                        "inq",
                        "cnf",
                        "pci",
                        "ci",
                        "cu",
                        "co",
                        "pco",
                        "ps",
                        "mk",
                        "acc",
                        "sns",
                        "upsell",
                        "crm"
                    ],
                    "type": "string"
                }
            }
        },
        "columns": [
            {
                "field": "title",
                "headerName": "Name",
                "width": 200
            },
            {
                "field": "experience_type",
                "headerName": "Experience type",
                "width": 200
            },
            {
                "field": "icon_url",
                "headerName": "Icon",
                "width": 200,
                "type": "icon"
            },
            {
                "field": "picture_url",
                "headerName": "Header",
                "width": 200,
                "type": "icon"
            }
        ]
    }
]

export const accountTierEnumMap = {
    "free": "free",
    "new": "new",
    "paid": "paid",
    "suspended": "sus",
    "cancelled": "cncl",
    "offboarded": "off"
}

export const currencyEnum = {
    "USD": "USD",
    "AED": "AED",
    "AFN": "AFN",
    "ALL": "ALL",
    "AMD": "AMD",
    "ANG": "ANG",
    "AOA": "AOA",
    "ARS": "ARS",
    "AUD": "AUD",
    "AWG": "AWG",
    "AZN": "AZN",
    "BAM": "BAM",
    "BBD": "BBD",
    "BDT": "BDT",
    "BGN": "BGN",
    "BHD": "BHD",
    "BIF": "BIF",
    "BMD": "BMD",
    "BND": "BND",
    "BOB": "BOB",
    "BOV": "BOV",
    "BRL": "BRL",
    "BSD": "BSD",
    "BTN": "BTN",
    "BWP": "BWP",
    "BYN": "BYN",
    "BZD": "BZD",
    "CAD": "CAD",
    "CDF": "CDF",
    "CHE": "CHE",
    "CHF": "CHF",
    "CHW": "CHW",
    "CLF": "CLF",
    "CLP": "CLP",
    "CNY": "CNY",
    "COP": "COP",
    "COU": "COU",
    "CRC": "CRC",
    "CUC": "CUC",
    "CUP": "CUP",
    "CVE": "CVE",
    "CZK": "CZK",
    "DJF": "DJF",
    "DKK": "DKK",
    "DOP": "DOP",
    "DZD": "DZD",
    "EGP": "EGP",
    "ERN": "ERN",
    "ETB": "ETB",
    "EUR": "EUR",
    "FJD": "FJD",
    "FKP": "FKP",
    "GBP": "GBP",
    "GEL": "GEL",
    "GHS": "GHS",
    "GIP": "GIP",
    "GMD": "GMD",
    "GNF": "GNF",
    "GTQ": "GTQ",
    "GYD": "GYD",
    "HKD": "HKD",
    "HNL": "HNL",
    "HRK": "HRK",
    "HTG": "HTG",
    "HUF": "HUF",
    "IDR": "IDR",
    "ILS": "ILS",
    "INR": "INR",
    "IQD": "IQD",
    "IRR": "IRR",
    "ISK": "ISK",
    "JMD": "JMD",
    "JOD": "JOD",
    "JPY": "JPY",
    "KES": "KES",
    "KGS": "KGS",
    "KHR": "KHR",
    "KMF": "KMF",
    "KPW": "KPW",
    "KRW": "KRW",
    "KWD": "KWD",
    "KYD": "KYD",
    "KZT": "KZT",
    "LAK": "LAK",
    "LBP": "LBP",
    "LKR": "LKR",
    "LRD": "LRD",
    "LSL": "LSL",
    "LYD": "LYD",
    "MAD": "MAD",
    "MDL": "MDL",
    "MGA": "MGA",
    "MKD": "MKD",
    "MMK": "MMK",
    "MNT": "MNT",
    "MOP": "MOP",
    "MRU": "MRU",
    "MUR": "MUR",
    "MVR": "MVR",
    "MWK": "MWK",
    "MXN": "MXN",
    "MXV": "MXV",
    "MYR": "MYR",
    "MZN": "MZN",
    "NAD": "NAD",
    "NGN": "NGN",
    "NIO": "NIO",
    "NOK": "NOK",
    "NPR": "NPR",
    "NZD": "NZD",
    "OMR": "OMR",
    "PAB": "PAB",
    "PEN": "PEN",
    "PGK": "PGK",
    "PHP": "PHP",
    "PKR": "PKR",
    "PLN": "PLN",
    "PYG": "PYG",
    "QAR": "QAR",
    "RON": "RON",
    "RSD": "RSD",
    "RUB": "RUB",
    "RWF": "RWF",
    "SAR": "SAR",
    "SBD": "SBD",
    "SCR": "SCR",
    "SDG": "SDG",
    "SEK": "SEK",
    "SGD": "SGD",
    "SHP": "SHP",
    "SLL": "SLL",
    "SOS": "SOS",
    "SRD": "SRD",
    "SSP": "SSP",
    "STN": "STN",
    "SVC": "SVC",
    "SYP": "SYP",
    "SZL": "SZL",
    "THB": "THB",
    "TJS": "TJS",
    "TMT": "TMT",
    "TND": "TND",
    "TOP": "TOP",
    "TRY": "TRY",
    "TTD": "TTD",
    "TWD": "TWD",
    "TZS": "TZS",
    "UAH": "UAH",
    "UGX": "UGX",
    "ZAR": "ZAR"
}

export const billing_plans = [
    {
        "subscription_id": "nso",
        "name": "Enso Connect",
        "unit": "listing",
        "interval": "month",
        "notion_database_id": "6cbd83413f214ae5ad24a11e084f5005"
    },
    {
        "subscription_id": "ah",
        "name": "Autohost - Automation Plan",
        "unit": "booking",
        "interval": "month",
        "notion_database_id": "a4f377c72af443488b6ce79d7abf0651"
    },
    {
        "subscription_id": "ahp",
        "name": "Autohost - Automation Plus Plan",
        "unit": "booking",
        "interval": "month",
        "notion_database_id": "b97aad0cae954356bfdb3f4a03c1d2f2"
    },
    {
        "subscription_id": "ahc",
        "name": "Autohost - Background Check Plan",
        "unit": "booking",
        "interval": "month",
        "notion_database_id": "6143fc5857c2438d8f43dd15c1a4ba5f"
    },
    {
        "subscription_id": "sh",
        "name": "Superhog - Verification Plan",
        "unit": "booking",
        "interval": "month",
        "notion_database_id": "e4742f3bfed345d8a1a4f441e863493f"
    },
    {
        "subscription_id": "shp",
        "name": "Superhog - Verification + Coverage Plan",
        "unit": "booking",
        "interval": "month",
        "notion_database_id": "f6be7bb3eba44c85be45799b5582a4c6"
    },
    {
        "subscription_id": "sms",
        "name": "SMS",
        "unit": "message",
        "interval": "month",
        "notion_database_id": "7d39b03ef17448349b8088c0d5db486c"
    },
    {
        "subscription_id": "whs",
        "name": "Whatsapp",
        "unit": "message",
        "interval": "month",
        "notion_database_id": "7d39b03ef17448349b8088c0d5db486c"
    },
    {
        "subscription_id": "ai",
        "name": "Enso AI",
        "unit": "listing",
        "interval": "month",
        "notion_database_id": "41e105abcb414ff5ad89831c8c5c5fed"
    },
    {
        "subscription_id": "dvc",
        "name": "Smart Device",
        "unit": "device",
        "interval": "month",
        "notion_database_id": "c4101452a0134c0cb91f7b6c676208f6"
    },
    {
        "subscription_id": "chk",
        "name": "Chekin",
        "unit": "listing",
        "interval": "month",
        "notion_database_id": "00bda72b9fac46efa619b8f9e2a820c2"
    }
]